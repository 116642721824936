import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB-0XETj32UYv-2vu0-VyOjBlvSwLRp6Bo",
  authDomain: "eitmamland-9c301.firebaseapp.com",
  projectId: "eitmamland-9c301",
  storageBucket: "eitmamland-9c301.appspot.com",
  messagingSenderId: "429112667949",
  appId: "1:429112667949:web:c69ac94a259cd9bf012da4",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
export { db, storage, messaging };

import { setNewLeaveErrors, setNewLeaveForm, clearNewLeaveForm, setUserLeaves } from "../../../redux/reduxSlice/leavesSlice";
import { setLoading } from "../../../redux/reduxSlice/globalSlice";
import { db } from "../../../firebase/firebase";
import { addDoc, collection, setDoc } from "firebase/firestore";
import dateUtils from "../../../utils/dateUtils";
import { message } from "antd";
import { fetchLeaveData, getLeavesByUserId } from "../getLeaves/getLeavesFunctions";
import { uploadImage } from "../../../utils/uploadImageToFirebase";
// import { sendNotification } from "../../../utils/pushNotificationFunc";
import { sendEmail } from "../../../utils/sendEmail";

const validateAddNewLeave = (dispatch, name, value, t) => {
    if (name === "description") {
        if (value === "") {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newLeaveErrors;
                dispatch(
                    setNewLeaveErrors({
                        ...currentErrors,
                        [name]: t("leaves.errorDescription"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().employees.newLeaveErrors;
                dispatch(
                    setNewLeaveErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else {
        dispatch((dispatch, getState) => {
            const currentErrors = getState().employees.newLeaveErrors;
            dispatch(
                setNewLeaveErrors({
                    ...currentErrors,
                    [name]: "",
                })
            );
        });
    }
};

const validateAllFieldsAddNewLeave = (dispatch, newLeaveForm, newLeaveErrors, t) => {
    let errors = { ...newLeaveErrors }
    if (newLeaveForm.startDate === "") {
        errors.startDate = t("leaves.errorStartDate");
    }
    if (newLeaveForm.endDate === "") {
        errors.endDate = t("leaves.errorEndDate");
    }
    if (newLeaveForm.description === "") {
        errors.description = t("leaves.errorDescription");
    }
    dispatch(setNewLeaveErrors(errors));
    return errors
};

export const handleNewLeaveInputChange = (event, newLeaveForm, dispatch, t) => {
    const { name, value, files } = event.target;
    if (name === "attachments") {
        if (newLeaveForm?.attachments?.length > 0) {
            const attachment = newLeaveForm?.attachments;
            if (files[0]) {
                dispatch(setNewLeaveForm({ ...newLeaveForm, [name]: [...attachment, files[0]] }));
            }
        } else {
            if (files[0]) {
                dispatch(setNewLeaveForm({ ...newLeaveForm, [name]: [files[0]] }));
            }
        }
    } else {
        dispatch(setNewLeaveForm({ ...newLeaveForm, [name]: value }));
        validateAddNewLeave(dispatch, name, value, t, newLeaveForm);
    }
};

export const handleRemoveAttachments = (dispatch, newLeaveForm, item) => {
    const newAttachments = newLeaveForm?.attachments?.filter((file) => file !== item)
    dispatch(setNewLeaveForm({ ...newLeaveForm, attachments: [...newAttachments] }));
}


export const handleSubmit = async (e, newLeaveForm, newLeaveErrors, dispatch, navigate, t, user, type) => {
    e.preventDefault();
    const errors = validateAllFieldsAddNewLeave(dispatch, newLeaveForm, newLeaveErrors, t);
    if (Object.values(errors).some((error) => error !== "")) {
        message.error(t("global.errorForm"));
        return;
    } else if (Object.values(newLeaveErrors).length === 0) {
        message.error(t("global.errorForm"));
        return;
    }
    else {
        try {
            dispatch(setLoading(true));
            if (type === "annualLeave" && user?.remainingAnnualLeaves === 0) {
                message.error(t("leaves.leavesLeft"))
                dispatch(setLoading(false));
                dispatch(clearNewLeaveForm());
                return
            } else if (type === "sickLeave" && user?.remainingSickLeaves === 0) {
                message.error(t("leaves.leavesLeft"))
                dispatch(setLoading(false));
                dispatch(clearNewLeaveForm());
                return
            } else {
                await handleAddNewLeave(newLeaveForm, navigate, dispatch, t, user, type);
            }
        } catch (error) {
            dispatch(clearNewLeaveForm());
            dispatch(setLoading(false))
            message.error(t("leaves.leaveError"));
        }
    }
}

const userRef = collection(db, "leaves");

const handleAddNewLeave = async (newLeaveForm, navigate, dispatch, t, user, type) => {
    try {
        let attachment = [];
        if (newLeaveForm?.attachments?.length > 0) {
            const uploadPromises = newLeaveForm.attachments.map((item) =>
                uploadImage(
                    "leaves",
                    item,
                    `user-${user?.userId}-${Math.random(1000)}`,
                    { contentType: "image/png" }
                )
            );
            try {
                attachment = await Promise.all(uploadPromises);
            } catch (error) {
                message.error(error.message);
            }
        }
        const docRef = await addDoc(userRef, {
            ...user,
            attachments: attachment,
            startDate: newLeaveForm?.startDate,
            endDate: newLeaveForm?.endDate,
            description: newLeaveForm?.description,
            totalLeaveDays: newLeaveForm?.totalLeaveDays,
            hrView: false,
            supervisorView: false,
            hrStatus: "pending",
            supervisorStatus: "pending",
            hrComments: "",
            supervisorComments: "",
            type: type,
            createdAt: dateUtils.getCurrentDate(),
            updatedAt: dateUtils.getCurrentDate()
        });
        await setDoc(docRef, {
            leaveId: docRef.id,
            updatedAt: dateUtils?.getCurrentDate()
        }, { merge: true });
        const userLeaves = await getLeavesByUserId(user?.userId)
        setUserLeaves(userLeaves)
        // await sendNotification("leave", newLeaveForm?.description, user?.englishName)

        await sendEmail("leave", newLeaveForm, user, type)
        await fetchLeaveData(dispatch);
        if (type === "sickLeave") {
            if (user?.role === "user") {
                navigate("/eitmamland/user/sickLeave");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr/sickLeave");
            } else {
                navigate("/eitmamland/supervisor/sickLeave");
            }
        } else if (type === "annualLeave") {
            if (user?.role === "user") {
                navigate("/eitmamland/user/annualLeave");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr/annualLeave");
            } else {
                navigate("/eitmamland/supervisor/annualLeave");
            }
        } else {
            if (user?.role === "user") {
                navigate("/eitmamland/user");
            } else if (user?.role === "hr") {
                navigate("/eitmamland/hr");
            } else {
                navigate("/eitmamland/supervisor");
            }
        }
        message.success(t("leaves.leaveSuccess"));
        dispatch(setLoading(false));
        dispatch(clearNewLeaveForm());
    } catch (error) {
        throw new Error(error);
    }
}
import { doc, setDoc } from "firebase/firestore";
import {
  clearEditEmployeeForm,
  setEditEmployeeForm,
} from "../../../redux/reduxSlice/employeesSlice";
import { setLoading } from "../../../redux/reduxSlice/globalSlice";
import {
  validateEditEmployee,
  validateRequiredFieldsEditEmployee,
} from "./validateEditEmployee";
import { message } from "antd";
import { fetchData, getUserById } from "../employeesFunctions";
import dateUtils from "../../../utils/dateUtils";
import { db } from "../../../firebase/firebase";
import { updateImage, uploadImage } from "../../../utils/uploadImageToFirebase";
import { findImagePath } from "../../../utils/findImageFirebasePath";
import { setUser } from "../../../redux/reduxSlice/userSlice";

export const handleEditEmployeeInputChange = (
  event,
  editEmployeeForm,
  dispatch,
  t,
  employees
) => {
  const { name, value, files } = event.target;
  if (
    name === "profileImage" ||
    name === "profileImageNew" ||
    name === "emirateImage" ||
    name === "emirateImageNew" ||
    name === "insuranceImage" ||
    name === "insuranceImageNew" ||
    name === "passportImage" ||
    name === "passportImageNew" ||
    name === "residencyImage" ||
    name === "residencyImageNew" ||
    name === "visaImage" ||
    name === "visaImageNew" ||
    name === "employmentContract" ||
    name === "employmentContractNew" ||
    name === "approvedContract" ||
    name === "approvedContractNew" ||
    name === "workPermitImage" ||
    name === "workPermitImageNew"
  ) {
    dispatch(setEditEmployeeForm({ ...editEmployeeForm, [name]: files[0] }));
  } else {
    dispatch(setEditEmployeeForm({ ...editEmployeeForm, [name]: value }));
    validateEditEmployee(dispatch, name, value, t, employees);
  }
};

export const handleSubmit = async (
  e,
  editEmployeeForm,
  editEmployeeErrors,
  dispatch,
  navigate,
  t,
  flag
) => {
  e.preventDefault();
  const errors = validateRequiredFieldsEditEmployee(
    dispatch,
    editEmployeeForm,
    editEmployeeErrors,
    t
  );
  if (Object.values(errors).some((error) => error !== "")) {
    message.error(t("global.errorForm"));
    return;
  } else {
    try {
      dispatch(setLoading(true));
      await handleEditEmplyee(editEmployeeForm, navigate, dispatch, t, flag);
    } catch (error) {
      dispatch(setLoading(false));
      message.error(t("employees.employeeError"));
    }
  }
};

const handleUploadProfileImage = async (editData) => {
  if (editData?.profileImage === undefined) {
    const url = await uploadImage(
      "profileImages",
      editData?.profileImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.profileImage);
    const url = await updateImage(imageRef, editData?.profileImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadEmirateImage = async (editData) => {
  if (editData?.emirateImage === undefined) {
    const url = await uploadImage(
      "emirateImages",
      editData?.emirateImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.emirateImage);
    const url = await updateImage(imageRef, editData?.emirateImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadInsuranceImage = async (editData) => {
  if (editData?.insuranceImage === undefined) {
    const url = await uploadImage(
      "insuranceImages",
      editData?.insuranceImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.insuranceImage);
    const url = await updateImage(imageRef, editData?.insuranceImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadPassportImage = async (editData) => {
  if (editData?.passportImage === undefined) {
    const url = await uploadImage(
      "passportImages",
      editData?.passportImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.passportImage);
    const url = await updateImage(imageRef, editData?.passportImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadResidencyImage = async (editData) => {
  if (editData?.residencyImage === undefined) {
    const url = await uploadImage(
      "residencyImages",
      editData?.residencyImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.residencyImage);
    const url = await updateImage(imageRef, editData?.residencyImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadVisaImage = async (editData) => {
  if (editData?.visaImage === undefined) {
    const url = await uploadImage(
      "visaImages",
      editData?.visaImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.visaImage);
    const url = await updateImage(imageRef, editData?.visaImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadEmploymentContract = async (editData) => {
  if (editData?.employmentContract === undefined) {
    const url = await uploadImage(
      "employmentContractImages",
      editData?.employmentContractNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.employmentContract);
    const url = await updateImage(imageRef, editData?.employmentContractNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadApprovedContract = async (editData) => {
  if (editData?.approvedContract === undefined) {
    const url = await uploadImage(
      "approvedContractImages",
      editData?.approvedContractNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.approvedContract);
    const url = await updateImage(imageRef, editData?.approvedContractNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleUploadWorkPermitImage = async (editData) => {
  if (editData?.workPermitImage === undefined) {
    const url = await uploadImage(
      "workPermitImages",
      editData?.workPermitImageNew,
      `user-${editData?.userId}`,
      { contentType: "image/png" }
    );
    return url;
  } else {
    const imageRef = findImagePath(editData?.workPermitImage);
    const url = await updateImage(imageRef, editData?.workPermitImageNew, {
      contentType: "image/png",
    });
    return url;
  }
};
const handleEditEmplyee = async (
  editEmployeeForm,
  navigate,
  dispatch,
  t,
  flag
) => {
  try {
    let editData = { ...editEmployeeForm };
    if (editData?.profileImageNew !== undefined) {
      const profileImageUrl = await handleUploadProfileImage(editData);
      editData.profileImage = profileImageUrl;
      const { profileImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.emirateImageNew !== undefined) {
      const emirateImageUrl = await handleUploadEmirateImage(editData);
      editData.emirateImage = emirateImageUrl;
      const { emirateImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.insuranceImageNew !== undefined) {
      const insuranceImageUrl = await handleUploadInsuranceImage(editData);
      editData.insuranceImage = insuranceImageUrl;
      const { insuranceImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.passportImageNew !== undefined) {
      const passportImageUrl = await handleUploadPassportImage(editData);
      editData.passportImage = passportImageUrl;
      const { passportImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.residencyImageNew !== undefined) {
      const residencyImageUrl = await handleUploadResidencyImage(editData);
      editData.residencyImage = residencyImageUrl;
      const { residencyImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.visaImageNew !== undefined) {
      const visaImageUrl = await handleUploadVisaImage(editData);
      editData.visaImage = visaImageUrl;
      const { visaImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.employmentContractNew !== undefined) {
      const employmentContractUrl = await handleUploadEmploymentContract(
        editData
      );
      editData.employmentContract = employmentContractUrl;
      const { employmentContractNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.approvedContractNew !== undefined) {
      const approvedContractUrl = await handleUploadApprovedContract(editData);
      editData.approvedContract = approvedContractUrl;
      const { approvedContractNew, ...rest } = editData;
      editData = { ...rest };
    }
    if (editData?.workPermitImageNew !== undefined) {
      const workPermitImageUrl = await handleUploadWorkPermitImage(editData);
      editData.workPermitImage = workPermitImageUrl;
      const { workPermitImageNew, ...rest } = editData;
      editData = { ...rest };
    }
    const docRef = doc(db, "users", editData?.userId);
    await setDoc(
      docRef,
      {
        ...editData,
        remainingAnnualLeaves: parseFloat(editData?.remainingAnnualLeaves),
        remainingSickLeaves: parseFloat(editData?.remainingSickLeaves),
        updatedAt: dateUtils?.getCurrentDate(),
      },
      { merge: true }
    );
    await fetchData(dispatch);
    if (flag === "hrProfile") {
      const userData = await getUserById(editData?.userId);
      dispatch(setUser(userData));
      navigate("/eitmamland/hr/profile");
      message.success(t("hrProfile.editSuccess"));
    } else if (flag === "supervisorProfile") {
      const userData = await getUserById(editData?.userId);
      dispatch(setUser(userData));
      navigate("/eitmamland/supervisor/profile");
      message.success(t("hrProfile.editSuccess"));
    } else {
      navigate("/eitmamland/hr/employees");
      message.success(t("employees.employeeEditSuccess"));
    }
    dispatch(clearEditEmployeeForm());
    dispatch(setLoading(false));
  } catch (error) {
    throw new Error(error);
  }
};

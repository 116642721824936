import { message } from "antd";
import {
    clearGlobalStates, setChangePasswordForm,
    setChangePasswordFormErrors, setLoading
} from "../../redux/reduxSlice/globalSlice";
import validationUtils from "../../utils/validationUtils";
import { doc, setDoc } from "firebase/firestore";
import dateUtils from "../../utils/dateUtils";
import { db } from "../../firebase/firebase";
import { fetchData } from "../employees/employeesFunctions";

const validateFormField = (dispatch, name, value, t, changePasswordForm) => {
    if (name === "password") {
        const validPassword = validationUtils?.isPasswordValid(value)
        if (!validPassword) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().global.changePasswordFormErrors;
                dispatch(
                    setChangePasswordFormErrors({
                        ...currentErrors,
                        [name]: t("global.errorPassword"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().global.changePasswordFormErrors;
                dispatch(
                    setChangePasswordFormErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else if (name === "confirmPassword") {
        if (value !== changePasswordForm.password) {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().global.changePasswordFormErrors;
                dispatch(
                    setChangePasswordFormErrors({
                        ...currentErrors,
                        [name]: t("global.errorConfirmPassword"),
                    })
                );
            });
        } else {
            dispatch((dispatch, getState) => {
                const currentErrors = getState().global.changePasswordFormErrors;
                dispatch(
                    setChangePasswordFormErrors({
                        ...currentErrors,
                        [name]: "",
                    })
                );
            });
        }
    } else {
        dispatch((dispatch, getState) => {
            const currentErrors = getState().global.changePasswordFormErrors;
            dispatch(
                setChangePasswordFormErrors({
                    ...currentErrors,
                    [name]: "",
                })
            );
        });
    }
};





const handleChangePasswordInput = (e,
    changePasswordForm,
    dispatch,
    t) => {
    const { name, value } = e.target;
    dispatch(setChangePasswordForm({ ...changePasswordForm, [name]: value }));
    validateFormField(dispatch, name, value, t, changePasswordForm);
};

const handleChangePassword = async (changePasswordForm, user, dispatch) => {
    try {
        const currentDate = dateUtils.getCurrentDate();
        const docRef = doc(db, "users", user?.userId)
        const hashed = btoa(changePasswordForm.password);
        await setDoc(docRef, {
            password: hashed,
            updatedAt: currentDate
        }, { merge: true })
        await fetchData(dispatch);
    } catch (error) {
        throw new Error(error)
    }

}

const handleSubmit = async (e, changePasswordForm, changePasswordFormErrors, dispatch, t, user) => {
    e.preventDefault();
    for (const [name, value] of Object.entries(changePasswordForm)) {
        validateFormField(dispatch, name, value, t, changePasswordForm);
    }
    if (Object.values(changePasswordFormErrors).some((error) => error !== "")) {
        message.error(t("global.errorForm"));
        return;
    } else if (Object.values(changePasswordFormErrors).length === 0) {
        message.error(t("global.errorForm"));
        return;
    }
    else {
        try {
            dispatch(setLoading(true));
            await handleChangePassword(changePasswordForm, user, dispatch);
            message.success(t("global.changePasswordSuccess"))
            dispatch(clearGlobalStates());
        } catch (error) {
            dispatch(clearGlobalStates());
            message.error(t("global.changePasswordError"))
        }
    }
}
export { handleChangePasswordInput, handleSubmit }
import {
  clearLoginForm,
  setErrors,
  setFormData,
  setLoading,
} from "../../redux/reduxSlice/loginSlice";
import { message } from "antd";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { setUser } from "../../redux/reduxSlice/userSlice";
import { setShowPassword } from "../../redux/reduxSlice/globalSlice";
import { handleFCMTokenAfterLogin } from "../../utils/pushNotificationFunc";

const userRef = collection(db, "users");

const validateFormField = (dispatch, name, value) => {
  if (typeof value === "string" && value.trim() === "") {
    dispatch((dispatch, getState) => {
      const currentErrors = getState().login.errors;
      dispatch(
        setErrors({
          ...currentErrors,
          [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
        })
      );
    });
  } else {
    dispatch((dispatch, getState) => {
      const currentErrors = getState().login.errors;
      dispatch(
        setErrors({
          ...currentErrors,
          [name]: "",
        })
      );
    });
  }
};

const handleInputChange = (event, formData, dispatch) => {
  const { name, value } = event.target;
  dispatch(setFormData({ ...formData, [name]: value }));
  validateFormField(dispatch, name, value);
};

const handleLogin = async (formData, navigate, dispatch, t) => {
  try {
    dispatch(setLoading(true));
    // Fetch the user by email
    const userQuery = await getDocs(
      query(userRef, where("email", "==", formData?.email))
    );
    const userArray = userQuery.docs.map((doc) => ({ ...doc.data() }));

    if (userArray.length === 0) {
      dispatch(setLoading(false));
      message.error(t("login.errorLogin"));
      return;
    }
    const decodedPassword = atob(userArray[0]?.password);
    // Compare passwords
    if (decodedPassword === formData?.password) {
      dispatch(setLoading(false));
      dispatch(setUser(userArray[0]));
      navigate(
        userArray[0]?.role === "user"
          ? "/eitmamland/user"
          : userArray[0]?.role === "hr"
          ? "/eitmamland/hr"
          : "/eitmamland/supervisor"
      );
      dispatch(clearLoginForm());
      message.success(t("login.successLogin"));
      await handleFCMTokenAfterLogin(userArray[0]);
    } else {
      dispatch(setLoading(false));
      message.error(t("login.errorLogin"));
    }
  } catch (error) {
    dispatch(setLoading(false));
    throw error;
  }
};

const handleSubmit = async (event, formData, errors, navigate, dispatch, t) => {
  event.preventDefault();
  for (const [name, value] of Object.entries(formData)) {
    validateFormField(dispatch, name, value);
  }
  if (Object.values(errors).some((error) => error !== "")) {
    message.error(t("global.errorForm"));
    return;
  } else if (Object.values(errors).length === 0) {
    message.error(t("global.errorForm"));
    return;
  } else {
    try {
      dispatch(setLoading(true));
      await handleLogin(formData, navigate, dispatch, t);
    } catch (error) {
      dispatch(clearLoginForm());
      dispatch(setShowPassword(false));
      message.error(t("login.errorLogin"));
    }
  }
};

export { handleInputChange, handleSubmit };

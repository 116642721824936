import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase/firebase";

export const uploadImage = async (path, file, name, meta = {}, progressCallback) => {
    if (file && path && name) {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, path + "/" + name);
            const uploadTask = uploadBytesResumable(storageRef, file, meta);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    if (progressCallback) {
                        progressCallback(progress);
                    }
                },
                (error) => {
                    console.log(error);
                    reject(error);
                },

                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        resolve(url);
                    });
                }
            );
        });
    }
};

export const updateImage = async (storageRef, file, meta = {}, progressCallback) => {
    if (file && storageRef) {
        return new Promise((resolve, reject) => {
            const uploadTask = uploadBytesResumable(storageRef, file, meta);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    if (progressCallback) {
                        progressCallback(progress);
                    }
                },
                (error) => {
                    console.log(error);
                    reject(error);
                },
                async () => {
                    try {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(url);
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    } else {
        throw new Error("File and storage reference must be provided.");
    }
};
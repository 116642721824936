import { Button } from "@mui/material";
import { Popconfirm } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteEmployee } from "../../../../libs/employees/deleteEmployee/deleteEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function TableButtons({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <Button
        onClick={() => user?.role === "hr" ? navigate("/eitmamland/hr/viewEmployee", { state: data }) : navigate("/eitmamland/supervisor/viewEmployee", { state: data })}
        sx={{
          margin: "0.5rem",
          background: "#4caf50",
          "&:hover": {
            background: "#388e3c",
          },
        }}
        variant="contained"
        size="small"
      >
        {t("employees.viewButton")}
      </Button>
      <Popconfirm
        title={t("employees.deleteEmployeeTitle")}
        description={t("employees.deleteEmployee")}
        onConfirm={() => deleteEmployee(data, t, dispatch)}
        okText={t("employees.deleteYesButton")}
        cancelText={t("employees.deleteNoButton")}
        style={{ margin: "0.5rem" }}
      >
        <Button
          size="small"
          sx={{
            background: "#f44336",
            "&:hover": {
              background: "#d32f2f",
            },
          }}
          variant="contained"
        >
          {t("employees.deleteButton")}
        </Button>
      </Popconfirm>
    </>
  );
}

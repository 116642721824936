import axios from "axios";
import { getUserById, getUserByRole } from "../libs/employees/employeesFunctions";
import { urls } from "../assets/constants/urls";

export const sendEmail = async (type, newLeaveForm, user, leaveType) => {
  const HRs = await getUserByRole('hr');
  const Supervisors = await getUserByRole('supervisor');

  const emails = [
    ...HRs.filter(user => user.email).map(user => user.email),
    ...Supervisors.filter(user => user.email).map(user => user.email)
  ];


  const today = new Date().toLocaleDateString('en-GB'); // Format the date to dd/mm/yyyy

  const emailData = {
    to: emails,
    subject: `New ${type === 'leave' ? 'Leave' : 'Request'} Submitted by ${user?.englishName}.`,
    html: `
      <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 650px; margin: auto; padding: 20px; background-color: #ffffff; border: 1px solid #dddddd; border-radius: 8px;">
        <header style="text-align: center; padding-bottom: 20px; border-bottom: 1px solid #eeeeee;">
          <h1 style="color: #333333; font-size: 26px; margin: 0;">${type === 'leave' ? 'Leave Request' : 'New Request'} Submitted</h1>
          <p style="color: #777777; font-size: 16px; margin: 10px 0;">Submitted by: <strong>${user?.englishName}</strong></p>
          <p style="color: #777777; font-size: 16px; margin: 5px 0;">Apply Date: <strong>${today}</strong></p>
        </header>
  
        <main style="padding: 20px; background-color: #f7f7f7; border-radius: 6px; margin-top: 20px;">
          <section style="margin-bottom: 20px;">
            <h2 style="color: #444444; font-size: 20px; margin: 0;">Description</h2>
            <p style="color: #555555; font-size: 16px; line-height: 1.5; margin-top: 10px;">${newLeaveForm?.description}</p>
          </section>
  
          ${type === 'leave' ? `
            <section style="margin-bottom: 20px;">
              <h2 style="color: #444444; font-size: 20px; margin: 0;">Start Date</h2>
              <p style="color: #555555; font-size: 16px; line-height: 1.5; margin-top: 10px;">${newLeaveForm?.startDate}</p>
            </section>
  
            <section style="margin-bottom: 20px;">
              <h2 style="color: #444444; font-size: 20px; margin: 0;">End Date</h2>
              <p style="color: #555555; font-size: 16px; line-height: '1.5'; margin-top: '10px'};">${newLeaveForm?.endDate}</p>
            </section>
  
            <section style="margin-bottom: 20px;">
              <h2 style="color: #444444; font-size: 20px; margin: 0;">Total Leave Days</h2>
              <p style="color: #555555; font-size: 16px; line-height: 1.5; margin-top: 10px;">${newLeaveForm?.totalLeaveDays}</p>
            </section>
  
            <section style="margin-bottom: 20px;">
              <h2 style="color: #444444; font-size: 20px; margin: 0;">Current ${leaveType === 'sickLeave' ? 'Sick' : 'Annual'} Leaves Balance</h2>
              <p style="color: #555555; font-size: 16px; line-height: 1.5; margin-top: 10px;">${leaveType === 'sickLeave' ? user?.remainingSickLeaves : user?.remainingAnnualLeaves}</p>
            </section>
  
            <section>
              <h2 style="color: #444444; font-size: 20px; margin: 0;">After Approval Leave Balance</h2>
              <p style="color: #555555; font-size: 16px; line-height: 1.5; margin-top: 10px;">${leaveType === 'sickLeave' ? (user?.remainingSickLeaves - newLeaveForm?.totalLeaveDays) : (user?.remainingAnnualLeaves - newLeaveForm?.totalLeaveDays)}</p>
            </section>
          ` : ''}
        </main>
  
        <footer style="text-align: center; margin-top: 30px; padding-top: 20px; border-top: 1px solid #eeeeee;">
          <p style="color: #999999; font-size: 14px;">Please review this ${type === 'leave' ? 'leave request' : 'request'} at your earliest convenience.</p>
          <p style="margin-top: 15px;"><a href="https://www.eitmamland.com/" style="color: #1a73e8; text-decoration: none; font-size: 16px;">Visit the Eitmam Land Website</a></p>
        </footer>
      </div>
    `
  };




  const requestOptions = {
    method: 'POST',
    url: urls.email,
    headers: {
      'Content-Type': 'application/json',
    },
    data: emailData
  };

  try {
    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw new Error(`Error sending email: ${error.message}`);
  }
};


export const sendEmailToUser = async (type, id, response, name, body) => {
  const user = await getUserById(id);

  const today = new Date().toLocaleDateString('en-GB'); // Format the date to dd/mm/yyyy

  const emailData = {
    to: user?.email,
    subject: `${type === 'annualLeave' || type === 'sickLeave' ? 'Leave' : 'Request'} is ${response} by ${name}.`,
    html: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: auto; border: 1px solid #ddd; padding: 20px; background-color: #f9f9f9;">
        <div style="text-align: center; padding-bottom: 20px;">
          <h2 style="color: #333;">${type === 'annualLeave' || type === 'sickLeave' ? 'Leave' : 'Request'} ${response}</h2>
          <p style="color: #666; font-size: 14px;">${response} by: <strong>${name}</strong></p>
          <p style="color: #666; font-size: 14px;">Date: <strong>${today}</strong></p>
        </div>
        <div style="background-color: #fff; border: 1px solid #ddd; padding: 15px; border-radius: 5px;">
          <h3 style="color: #444; margin-top: 0;">Reason:</h3>
          <p style="color: #555; line-height: 1.5;">${body}</p>
        </div>
        <div style="text-align: center; margin-top: 30px;">
          <p style="color: #888; font-size: 12px;">This ${type === 'leave' ? 'leave' : 'request'} has been ${response} by ${name}. Please take any necessary actions or visit the Eitmam Land website for more details.</p>
          <p><a href="https://www.eitmamland.com/" style="color: #1a73e8; text-decoration: none;">Visit the Eitmam Land Website</a></p>
        </div>
      </div>
    `
  };


  const requestOptions = {
    method: 'POST',
    url: urls.email,
    headers: {
      'Content-Type': 'application/json',
    },
    data: emailData
  };

  try {
    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw new Error(`Error sending email: ${error.message}`);
  }
};
import { message } from "antd";
import { clearNewEmployeeForm, setNewEmployeeForm } from "../../../redux/reduxSlice/employeesSlice";
import { setLoading, setShowConfirmPassword, setShowPassword } from "../../../redux/reduxSlice/globalSlice";
import { validateAddNewEmployee, validateAllFieldsAddNewEmployee } from "./validateAddNewEmployee";
import { addDoc, collection, setDoc } from "firebase/firestore";
import dateUtils from "../../../utils/dateUtils";
import { db } from "../../../firebase/firebase";
import { fetchData } from "../employeesFunctions";

export const handleNewEmployeeInputChange = (event, newEmployeeForm, dispatch, t, employees) => {
    const { name, value } = event.target;
    dispatch(setNewEmployeeForm({ ...newEmployeeForm, [name]: value }));
    validateAddNewEmployee(dispatch, name, value, t, newEmployeeForm, employees);
};

export const handleSubmit = async (e, newEmployeeForm, newEmployeeErrors, dispatch, navigate, t, employees) => {
    e.preventDefault();
    const errors = validateAllFieldsAddNewEmployee(dispatch, newEmployeeForm, newEmployeeErrors, employees, t);
    if (Object.values(errors).some((error) => error !== "")) {
        message.error(t("global.errorForm"));
        return;
    } else if (Object.values(newEmployeeErrors).length === 0) {
        message.error(t("global.errorForm"));
        return;
    }
    else {
        try {
            dispatch(setLoading(true));
            await handleAddNewEmplyee(newEmployeeForm, navigate, dispatch, t);
        } catch (error) {
            dispatch(clearNewEmployeeForm());
            dispatch(setLoading(false))
            dispatch(setShowConfirmPassword(false));
            dispatch(setShowPassword(false));
            message.error(t("employees.employeeError"));
        }
    }
}

const userRef = collection(db, "users");

const handleAddNewEmplyee = async (newEmployeeForm, navigate, dispatch, t) => {
    try {
        const { confirmPassword, ...rest } = newEmployeeForm
        const today = new Date();
        const joiningDate = new Date(rest?.dateOfJoining);
        const annualLeaveUpdateDate = dateUtils?.getNextOrSameDate(rest?.dateOfJoining)
        const yearsDifference = today.getFullYear() - joiningDate.getFullYear();
        let sickLeaveUpdateDate;
        if (yearsDifference > 0) {
            const lastAnniversary = new Date(joiningDate);
            lastAnniversary.setFullYear(today.getFullYear());
            sickLeaveUpdateDate = dateUtils?.calculateOneYearAfter(lastAnniversary.toISOString().slice(0, 10));
        } else {
            sickLeaveUpdateDate = dateUtils?.calculateOneYearAfter(rest?.dateOfJoining);
        }
        const hashed = btoa(rest.password);
        const docRef = await addDoc(userRef, {
            englishName: rest?.englishName,
            email: rest?.email,
            phoneNumber: rest?.phoneNumber,
            dateOfJoining: rest?.dateOfJoining,
            remainingAnnualLeaves: parseFloat(rest?.remainingAnnualLeaves),
            remainingSickLeaves: parseFloat(rest?.remainingSickLeaves),
            password: hashed,
            role: rest?.role,
            gender: rest?.gender,
            annualLeaveUpdateDate,
            sickLeaveUpdateDate,
            createdAt: dateUtils.getCurrentDate(),
            updatedAt: dateUtils.getCurrentDate()
        });
        await setDoc(docRef, {
            userId: docRef.id,
            updatedAt: dateUtils?.getCurrentDate()
        }, { merge: true });
        await fetchData(dispatch);
        navigate("/eitmamland/hr/employees");
        message.success(t("employees.employeeSuccess"));
        dispatch(setLoading(false));
        dispatch(clearNewEmployeeForm());
    } catch (error) {
        throw new Error(error);
    }
}
import { message } from "antd";
import { messaging } from "../firebase/firebase";
import { getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import { getUserById, getUserByRole } from "../libs/employees/employeesFunctions";
import { urls } from "../assets/constants/urls";

const validateTokens = async (tokens, userId) => {
  const data = {
    tokens: tokens,
    userId: userId
  };

  try {
    const response = await axios.post(urls.validate, data, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const saveToken = async (userData, newToken) => {
  try {
    let tokens = [];
    if (userData?.fcmTokens !== undefined) {
      tokens = userData?.fcmTokens;
      tokens = [...tokens, newToken];
    } else {
      tokens = [newToken];
    }
    await validateTokens(tokens, userData?.userId)
    console.log("Token updated successfully");
  } catch (err) {
    console.error("Error saving FCM token:", err);
    return message.error("Error saving FCM token");
  }
};

const requestToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BEBHKQajjneJfgZ30smEsDSd_kQX_IvbAg8t1s4ABfzopsT-LzvS2eobt4v4t-zcyGn0N_BRSuqN9ezX7i7oS9I",
    });
    return token;
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

export const handleFCMTokenAfterLogin = async (userData) => {
  if ("Notification" in window) {
    try {
      const result = await Notification.requestPermission();
      if (result === "granted") {
        const token = await requestToken(); // Request FCM token if permission is granted
        await saveToken(userData, token); // Save the new token and remove the old one if needed

        // Optionally, set up a listener for when the token is refreshed
        onMessage(messaging, async () => {
          const refreshedToken = await messaging.getToken({
            vapidKey:
              "BEBHKQajjneJfgZ30smEsDSd_kQX_IvbAg8t1s4ABfzopsT-LzvS2eobt4v4t-zcyGn0N_BRSuqN9ezX7i7oS9I",
          });
          await saveToken(userData, refreshedToken); // Save the refreshed token and remove the old one if needed
        });
      } else if (result === "denied") {
        message.error("Notification permission denied.");
      } else if (result === "default") {
        message.error("Notification permission request dismissed.");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  } else {
    console.log("This browser does not support notifications.");
  }
};


export const sendNotification = async (type, body, name) => {
  try {
    // Fetch HRs and Supervisors
    const HRs = await getUserByRole('hr');
    const Supervisors = await getUserByRole('supervisor');
    // Extract FCM tokens from HRs and Supervisors
    const tokens = [
      ...HRs.filter(user => user.fcmTokens !== undefined && user.fcmTokens !== null)
        .flatMap(user => user.fcmTokens),
      ...Supervisors.filter(user => user.fcmTokens !== undefined && user.fcmTokens !== null)
        .flatMap(user => user.fcmTokens)
    ];
    
    // Check if there are any tokens to send notifications
    if (tokens.length === 0) {
      console.log('No FCM tokens found for HRs or Supervisors.');
      return;
    }

    // Define the notification message
    const title = type === 'request'
      ? `New Request is submitted by ${name}.`
      : `New Leave is submitted by ${name}.`;

    const data = {
      tokens,
      message: {
        title,
        body
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await axios.post(urls.notification, data, { headers });
    return response.data;
  } catch (error) {
    throw new Error(`Error sending notification: ${error.message}`);
  }
};

export const sendNotificationToUser = async (type, id, response, name) => {
  try {
    const user = await getUserById(id)
    // Check if there are any tokens to send notifications
    if (user?.fcmTokens === undefined || user?.fcmTokens === null || user?.fcmTokens?.length === 0) {
      console.log('No FCM tokens found.');
      return;
    }
    // Define the notification message
    const title = type === 'annualLeave' || type === 'sickLeave'
      ? `Leave ${response}`
      : `Request ${response}`;

    const body = type === 'annualLeave' || type === 'sickLeave'
      ? `Leave is ${response} by ${name}.`
      : `Request is ${response} by ${name}.`;

    const data = {
      tokens: user?.fcmTokens,
      message: {
        title,
        body
      }
    };
    // Send the notification
    const headers = {
      'Content-Type': 'application/json',
    };
    const responses = await axios.post(urls.notification, data, { headers });
    return responses.data;
  } catch (error) {
    throw new Error(`Error sending notification: ${error.message}`);
  }
};

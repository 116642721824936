import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowConfirmPassword, setShowPassword } from '../../../redux/reduxSlice/globalSlice';
import { handleChangePasswordInput, handleSubmit } from '../../../libs/changePassword/changePasswordFunctions';

export default function ChangePassword() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { changePasswordForm, changePasswordFormErrors,
        showPassword, showConfirmPassword
    } = useSelector((state) => state.global);
    const { editEmployeeForm } = useSelector((state) => state.employees);
    return (
        <>
            <div className='global-main-outer'>
                <h1>
                    {t("global.changePassword")}
                </h1>
                <div className="global-main" style={{ flexDirection: "column" }}>
                    <TextField
                        required
                        fullWidth
                        id="password"
                        name="password"
                        autoComplete="password"
                        autoFocus
                        error={!!changePasswordFormErrors?.password}
                        helperText={changePasswordFormErrors?.password}
                        value={changePasswordForm?.password}
                        onChange={(e) =>
                            handleChangePasswordInput(
                                e,
                                changePasswordForm,
                                dispatch,
                                t
                            )
                        }
                        size="small"
                        type={showPassword ? "text" : "password"}
                        label={t("global.password")}
                        placeholder="e.g. test@1244"
                        sx={{ margin: "0.5rem" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => dispatch(setShowPassword(!showPassword))}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        fullWidth
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="confirmPassword"
                        autoFocus
                        error={!!changePasswordFormErrors?.confirmPassword}
                        helperText={changePasswordFormErrors?.confirmPassword}
                        value={changePasswordForm?.confirmPassword}
                        onChange={(e) =>
                            handleChangePasswordInput(
                                e,
                                changePasswordForm,
                                dispatch,
                                t

                            )
                        }
                        size="small"
                        type={showConfirmPassword ? "text" : "password"}
                        label={t("global.confirmPassword")}
                        placeholder="e.g. test@1244"
                        sx={{ margin: "0.5rem" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            dispatch(setShowConfirmPassword(!showConfirmPassword))
                                        }
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Button
                    onClick={
                        (e) => handleSubmit(e, changePasswordForm, changePasswordFormErrors,
                            dispatch, t, editEmployeeForm)
                    }
                    sx={{
                        margin: "0.5rem 0rem",
                        background: "#d4df98",
                        "&:hover": {
                            background: "#a4b964",
                        },
                    }}
                    variant="contained"
                >
                    {t("global.savePassword")}
                </Button>
            </div>
        </>
    )
}

import { db } from "../../../firebase/firebase";
import { message } from "antd";
import { deleteDoc, doc } from "firebase/firestore";
import { fetchData } from "../employeesFunctions";
import { setLoading } from "../../../redux/reduxSlice/globalSlice"
import { deleteFileByURL } from "../../../utils/deleteFileByURL";

export const deleteEmployee = async (data, t, dispatch) => {
    try {
        dispatch(setLoading(true))
        const userDoc = doc(db, "users", data?.userId);
        if (data?.profileImage !== undefined) {
            await deleteFileByURL(data?.profileImage);
        }
        if (data?.emirateImage !== undefined) {
            await deleteFileByURL(data?.emirateImage);
        }
        if (data?.insuranceImage !== undefined) {
            await deleteFileByURL(data?.insuranceImage);
        }
        if (data?.passportImage !== undefined) {
            await deleteFileByURL(data?.passportImage);
        }
        if (data?.residencyImage !== undefined) {
            await deleteFileByURL(data?.residencyImage);
        }
        if (data?.visaImage !== undefined) {
            await deleteFileByURL(data?.visaImage);
        }
        if (data?.employmentContract !== undefined) {
            await deleteFileByURL(data?.employmentContract);
        }
        if (data?.approvedContract !== undefined) {
            await deleteFileByURL(data?.approvedContract);
        }
        if (data?.workPermitImage !== undefined) {
            await deleteFileByURL(data?.workPermitImage);
        }
        await deleteDoc(userDoc);
        await fetchData(dispatch)
        message.success(t("employees.deleteEmployeeSuccess"))
    } catch (error) {
        dispatch(setLoading(false))
        message.error(t("employees.deleteEmployeeError"))
    }
}